<template>
  <div>
    <main class="main-section container-fluid">
      <div class="row">
        <div class="col-lg-5 col-xl-6">
          <div class="text-center logo-container">
            <img src="../assets/logo.svg" width="234" height="55" alt="">
          </div>
          <h1>
            Help a Friend Find Their <span class="caveat">Dream&nbsp;Home</span>
          </h1>
          <h2 class="sub-copy">
            <span>Fill up the Interested Buyer Form</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_254_19" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_254_19)">
              <path d="M13.8462 17.6538L12.7923 16.5693L16.6115 12.75H1.5V11.25H16.6115L12.7923 7.43075L13.8462 6.34625L19.5 12L13.8462 17.6538Z" fill="white"/>
              </g>
            </svg>
          </h2>
          <div class="line-svg d-none d-lg-flex"></div>
          <div class="contact-container d-none d-lg-flex">
            <a href="tel:+918080190190 " class="phone">
              +91 8080 190190
            </a>
            <a href="mailto:hello@propertypistol.com" class="mail">hello@propertypistol.com</a>
          </div>
        </div>
        <div class="col-lg-7 col-xl-6">
          <form class="main-form"  @submit.prevent="generateOTP">
            <h3>Interested Buyer’s Information </h3>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Full Name <span class="text-danger">*</span> </label>
                  <input type="text" v-model="lead.full_name" required class="form-control" id="name" placeholder="Enter Full Name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="phone" class="form-label">Phone Number <span class="text-danger">*</span> </label>
                  <input type="number" v-model="lead.primary_phone" minlength="10" required class="form-control" id="phone" placeholder="Enter Phone Number">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="city" class="form-label">City <span class="text-danger">*</span> </label>
                  <input type="text" v-model="lead.city" required class="form-control" id="city" placeholder="Enter City's Name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="project" class="form-label">Preferrered Project <span class="opacity-50">(Optional)</span></label>
                  <multiselect  v-model="lead.sub_project_id" :options-limit="4000"  :multiple="false"  :options="projects" label="name"  track-by="id" placeholder="Search Project"></multiselect>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="pb-4">
                  <label for="name" class="form-label">Additional Comment <span class="opacity-50">(Optional)</span></label>
                  <textarea class="form-control" v-model="lead.comment" placeholder="Add comment" id="floatingTextarea" style="height: 60px;"></textarea>
                </div>
              </div>
              <div class="col-12">
                <h3>My Information </h3>
                <hr>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name2" class="form-label">Full Name <span class="text-danger">*</span> </label>
                  <input type="text" class="form-control" required v-model="lead.affiliate_partner_name" id="name2" placeholder="Enter Full Name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="phone2" class="form-label">Phone Number <span class="text-danger">*</span> </label>
                  <div class="input-group flex-nowrap">
                    <v-select
                      v-model="lead.referrer_country_id"
                      :options="country_codes"
                      label="iso_code"
                      track-by="id"
                      :reduce="country => country.id"
                      placeholder="Select a country"
                    >
                      <template #option="option">
                        <CountryFlag :country="option.iso_code" size="small"  class="flag-icon" />
                        (+{{ option.isd_code }})
                      </template>
                      <template #selected-option="option">
                        <CountryFlag :country="option.iso_code" size="small" class="flag-icon" />
                        (+{{ option.isd_code }})
                      </template>
                    </v-select>
                      <input
                        type="number"
                        required
                        class="form-control w-67"
                        v-model="lead.affiliate_partner_contact" minlength="10" id="phone2" placeholder="Enter Phone Number"
                      />
                    </div>
                </div>
              </div>
              <div class="col-12 text-center my-4">
                <button type="submit" class="btn-main">Submit</button>
              </div>
              <div class="disclaimer">I authorize PROPERTY PISTOL REALTY PRIVATE LIMITED and its representatives to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DNC/NDNC</div>
            </div>
          </form>
          <div class="line-svg d-block d-lg-none mt-5"></div>
          <div class="contact-container d-flex d-lg-none">
            <a href="tel:+91808019019" class="phone">
              +91 8080 190190
            </a>
            <a href="mailto:hello@propertypistol.com" class="mail">hello@propertypistol.com</a>
          </div>
        </div>
      </div>
      <div class="chair">
        <img src="../assets/chair.png" alt="">
      </div>
    </main>
  </div>
    <div class="modal fade" id="successModal" ref="successModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_254_183" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
              <rect width="20" height="20" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_254_183)">
              <path d="M5.33317 15.8333L4.1665 14.6666L8.83317 9.99996L4.1665 5.33329L5.33317 4.16663L9.99984 8.83329L14.6665 4.16663L15.8332 5.33329L11.1665 9.99996L15.8332 14.6666L14.6665 15.8333L9.99984 11.1666L5.33317 15.8333Z" fill="#D9D9D9"/>
              </g>
              </svg>
            </button>
            <div class="text-center mb-4 mt-4">
              <img src="../assets/success.gif" width="90" height="90" alt="">
            </div>
            <div class="success-message">Thank you so much for the referral!</div>
            <div class="success-submessage">We really appreciate your support and consideration.</div>
            <button type="button" class="btn-main" data-bs-dismiss="modal" aria-label="Close">
              Give More Referrals
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="otpModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_254_183" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
              <rect width="20" height="20" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_254_183)">
              <path d="M5.33317 15.8333L4.1665 14.6666L8.83317 9.99996L4.1665 5.33329L5.33317 4.16663L9.99984 8.83329L14.6665 4.16663L15.8332 5.33329L11.1665 9.99996L15.8332 14.6666L14.6665 15.8333L9.99984 11.1666L5.33317 15.8333Z" fill="#D9D9D9"/>
              </g>
              </svg>
            </button>
            <div class="otp-title">OTP Verification</div>
           <div class="otp-sub-wrapper">
            <div class="otp-subtitle">OTP was sent to <span>{{ lead.affiliate_partner_contact }}</span></div>
            <span v-if="timeLeft > 0"  class="otp-subtitle">Resend OTP in {{ timeLeft }} sec</span>
            <button v-else @click="generateOTP" type="button" class="btn-resend">Resend OTP </button>
           </div>
            <div class="mx-auto my-5">
              <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                :conditionalClass="['one', 'two', 'three', 'four']"
                separator=""
                :input-attributes="{ inputmode: 'tel' }"
                :num-inputs="6"
                :should-auto-focus="true"
                :should-focus-order="true"
                :placeholder="['-', '-', '-', '-', '-', '-']"
                @on-complete="handleOnComplete"
              />
            </div>
            <button type="button" class="btn-main" :disabled="!this.otp"  @click="validateOtp"  aria-label="Close">
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css'; // Import Vue Select styles
import CountryFlag from 'vue-country-flag-next'
import {decodeLeadParams} from '@/assets/scripts/utility.js';
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    CountryFlag,
    VSelect
  },
  
  data() {
    return {
      lead: {
        full_name: null,
        primary_phone: null,
        city: null,
        sub_project_id: null,
        sub_source: null,
        referrer_executive_id: null,
        user_id: null,
        affiliate_partner_name: null,
        affiliate_partner_contact: null,
        comment:null,
        referrer_country_id: 1,
      },
      otp: '',
      fileErrorMessage: null,
      timeLeft: 30,
      intervalId: null,
      projects: [],
      country_codes: [{
        id: 1,
        iso_code: 'IN',
        isd_code: '91',
      }],
    }
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      let decode_value = atob(Object.keys(this.$route.query)[0])
      var user_id = decode_value.split('=')[1]
    }
    if (user_id) {
      this.$cookies.set('user_id', user_id)
    } else if(this.$cookies.get('user_id')) {
    } else {
      this.$cookies.set('user_id', 5)
    }
    this.$store.dispatch('setLoading', true);
    this.$axios.get(`leads/sub_projects`).then((response) => {
      this.$store.dispatch('setLoading', false);
        this.projects = response.data.sub_projects
      }).catch(err => {
        this.$store.dispatch('setLoading', false);
      })
      this.$axios.get(`leads/referral_countries`).then((response) => {
        this.country_codes = response.data.countries
      }).catch(err => {
        this.$store.dispatch('setLoading', false);
      })
    

  },
  methods: {
    startTimer() {
        this.timeLeft = 30;
        this.intervalId = setInterval(() => {
          this.timeLeft--;
          if (this.timeLeft <= 0) {
            clearInterval(this.intervalId);
          }
        }, 1000);
    },
    beforeDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    handleOnComplete(value) {
      this.otp = value
    },
    generateOTP() {
    this.$store.dispatch('setLoading', true);
    console.log(this.lead.referrer_country_id)
    this.$axios.post(`leads/send_otp?phone=${this.lead.affiliate_partner_contact}&referrer_country_id=${this.lead.referrer_country_id}`).then((response) => {
      this.$store.dispatch('setLoading', false);
      $('#otpModal').modal('show');
      this.startTimer()
    }).catch(err => {
      this.$store.dispatch('setLoading', false);
      if (err.response.status === 500) {
        swal({
          title: "Server Error!",
          icon: "error"
        });
      } else {
        swal({
          title: "Error",
          text: err.response.data.message,
          icon: "error"
        });
      }
    })
    },

    validateOtp(){
        this.$store.dispatch('setLoading', true);
        this.$axios.post(`leads/verify_otp?&referrer_country_id=${this.lead.referrer_country_id}`,{
          "otp" : this.otp
        }).then((res)=>{
          this.createLead();
          $('#otpModal').modal('hide');
          this.$refs.otpInput.clearInput();
        }).catch(err => {
          this.$store.dispatch('setLoading', false);
            if (err.response.status === 500) {
              swal({
                title: "Server Error!",
                icon: "error"
              });
            } else {
              this.$refs.otpInput.clearInput();
              swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error"
              });
            }
        })
      },
      createLead() {
      this.$store.dispatch('setLoading', true);
      let params = decodeLeadParams(this.lead)
      params.user_id = this.$cookies.get('user_id')
      params.referrer_executive_id = this.$cookies.get('user_id')
      this.$axios
        .post("leads/create_refereral_leads", params)
        .then(res => {
          this.$store.dispatch('setLoading', false);
          this.lead= {referrer_country_id: 1,}
          this.$refs.otpInput.clearInput();
          $('#successModal').modal('show');
        })
        .catch(err => {
          this.$store.dispatch('setLoading', false);
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
      },

  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.w-33 {
  width: 33%;
}

.w-67 {
  width: 67%;
}
</style>