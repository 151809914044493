

export function decodeLeadParams(leadparams){
    let toSend = {}
    toSend['full_name'] = leadparams.full_name
    toSend['primary_phone'] = leadparams.primary_phone.toString()
    toSend['city'] = leadparams.city
    if (leadparams.sub_project_id) {
      toSend['sub_project_id'] = leadparams.sub_project_id.id
    } else {
      toSend['sub_project_id'] = 13946
    }
    toSend['enquiry_source_id'] = 193
    toSend['referrer_executive_id'] = leadparams.referrer_executive_id
    toSend['sub_source'] = 'Affiliate Partner'
    toSend['affiliate_partner_name'] = leadparams.affiliate_partner_name
    toSend['affiliate_partner_contact'] = leadparams.affiliate_partner_contact.toString()
    toSend['comment'] = leadparams.comment
    toSend['user_id'] = leadparams.user_id
    toSend['referrer_country_id'] = leadparams.referrer_country_id
    return toSend;
  }